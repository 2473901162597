import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import styled from 'styled-components';


const GalleryContainer = styled.div`
	max-width: 1200px;
	margin: auto;

	.image-gallery-left-nav .image-gallery-svg,
	.image-gallery-right-nav .image-gallery-svg {
		height: 60px;
		width: 40px;
	}

	.image-gallery-slide img {
		max-width: 1200px;
		height: 500px;
		object-fit: cover;
		overflow: hidden;
		object-position: center center;
	}

	.image-gallery-thumbnail-image {
		height: 85px;
	}

	@media ${(props) => props.theme.breakpoint.mobile} {
		.image-gallery-slide img {
			height: 350px;
		}

		.image-gallery-left-nav, .image-gallery-right-nav {
			padding: 0;
		}
	}
`;


const Slider = ({ images }) => {

	const gallery = images.map((image) => {
		const img = getImage(image.localFile)?.images.fallback?.src;
		return { original: img, thumbnail: img };
	});

	return (
		<GalleryContainer>
			<ImageGallery
				items={gallery}
				showFullscreenButton={false}
				showPlayButton={false}
				autoPlay={true}
				slideInterval={6000}
			/>
		</GalleryContainer>
	);
};

export default Slider;