import { Box, Heading } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Slider from '../components/ImageSlider';
import { Container } from '../components/Layout/GlobalStyles';

const Layout = styled.div`
	margin-top: 16rem;

	h2 {
		padding-bottom: 1.5rem;
		text-align: center;
	}

	@media ${(props) => props.theme.breakpoint.mobile} {
		margin-top: 12rem;
		width: 100%;
		padding: 0;
	}
`;

const ServicePage = ({data}) => {
	const services = data.service.nodes[0];

	return (

			<Layout>
				<Heading fontSize={{ base: '20px', md: '36px', lg: '42px' }}>
					{services.title}
				</Heading>
				<Slider images={services.gallery} />
				<Container>
				<Box mt={20} pb={55}>
					<ReactMarkdown className="line-break">
						{services.content}
					</ReactMarkdown>
				</Box>
				</Container>
			</Layout>

	);
};


export const query = graphql`
	query ($slug: String!) {
		service: allStrapiServices(filter: { slug: { eq: $slug } }) {
			nodes {
				title
				slug
				locale
				content
				gallery {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 700, formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			}
		}
	}
`;

export default ServicePage;